(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('UserService', UserService)

    /** @ngInject */
    function UserService($rootScope, $http, $q, $window, DataApiService, SweetAlert, DataRepositorioService, AuthService) {

        $rootScope.errorLoadingUser = false;
        $rootScope.currentUser = {};

        var usuarioLoadDataStarted = false;
        var deferHandleUsuarioLoad = $q.defer();

        const UserService = {
            loadUsuario: loadUsuario,
            mostraErrorSwal: mostraErrorSwal,
            getUser: getUser,
            getPerfil: getPerfil,
            getAllUsers: getAllUsers,
            updateUser: updateUser,
            updatePerfil: updatePerfil,
            saveUser: saveUser,
            deleteUser: deleteUser,
            updatePassword: updatePassword,
            updatePasswordPerfil: updatePasswordPerfil
        };

        return UserService;

        function loadUsuario() {
            if (!UserService.usuario && !usuarioLoadDataStarted) {
                usuarioLoadDataStarted = true;

                var usuario = DataRepositorioService.get('userProfile');

                if (!usuario) {
                    window.loading_screen.finish();

                    mostraErrorSwal();

                    deferHandleUsuarioLoad.reject();
                } else {
                    $rootScope.currentUser = UserService.usuario = {
                        Id: usuario.primarysid,
                        Nome: usuario.unique_name,
                        Email: usuario.email
                    };

                    deferHandleUsuarioLoad.resolve();
                }
            }

            return deferHandleUsuarioLoad.promise;
        }

        function mostraErrorSwal() {
            $rootScope.errorLoadingUser = true;

            SweetAlert
                .swal({
                    title: 'OPS',
                    text: 'Ocorreram alguns problemas ao carregar seus dados!',
                    type: 'error',
                    confirmButtonColor: '#eea236',
                    confirmButtonText: 'Sair',
                    closeOnConfirm: false
                }, (isConfirm) => {
                    if (isConfirm) {
                        AuthService.doLogout();
                    }
                });
        }

        function getUser(userId) {
            return DataApiService.get(`usuarios/${userId}`);
        }

        function getPerfil(userId) {
            return DataApiService.get(`meu-perfil/${userId}`);
        }

        function getAllUsers() {
            return DataApiService.get('usuarios');
        }

        function updateUser(user) {
            return DataApiService.put('usuarios', user);
        }

        function updatePerfil(user) {
            return DataApiService.put(`meu-perfil/${user.Id}`, user);
        }

        function saveUser(user) {
            var usuarioPost = angular.copy(user);

            usuarioPost.Senha = Encrypt.Hash(usuarioPost.Senha).toString();

            return DataApiService.post('usuarios', usuarioPost);
        }

        function deleteUser(userId) {
            return DataApiService.delete(`usuarios/${userId}`);
        }

        function updatePassword(userPassword) {
            let defer = $q.defer();

            let password = {
                Id: userPassword.Id,
                SenhaAntiga: Encrypt.Hash(userPassword.Old).toString(),
                SenhaNova: Encrypt.Hash(userPassword.New).toString()
            };

            DataApiService
                .put('usuarios/senha', password)
                .then(
                    (successData) => defer.resolve(successData),
                    (errorData) => defer.reject(errorData)
                );

            return defer.promise;
        }

        function updatePasswordPerfil(userPassword) {
            let defer = $q.defer();

            let password = {
                Id: userPassword.Id,
                SenhaAntiga: Encrypt.Hash(userPassword.Old).toString(),
                SenhaNova: Encrypt.Hash(userPassword.New).toString()
            };

            DataApiService
                .put(`meu-perfil/senha/${userPassword.Id}`, password)
                .then(
                    (successData) => defer.resolve(successData),
                    (errorData) => defer.reject(errorData)
                );

            return defer.promise;
        }
    }
}());