(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('PageService', PageService)

    /** @ngInject */
    function PageService(DataApiService) {

        const PageService = {
            getAllPages: getAllPages,
            getPage: getPage,
            updatePage: updatePage,
            savePage: savePage,
            deletePage: deletePage
        };

        return PageService;

        function getAllPages() {
            return DataApiService.get('paginas');
        }

        function getPage(pageId) {
            return DataApiService.get(`paginas/${pageId}`);
        }

        function updatePage(page) {
            return DataApiService.put('paginas', page);
        }

        function savePage(page) {
            return DataApiService.post('paginas', page);
        }

        function deletePage(pageId) {
            return DataApiService.delete(`paginas/${pageId}`);
        }
    }
}());