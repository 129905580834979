(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('ModuleService', ModuleService)

    /** @ngInject */
    function ModuleService(DataApiService) {

        const ModuleService = {
            getAllModules: getAllModules,
            getModule: getModule,
            saveModule: saveModule,
            updateModule: updateModule,
            deleteModule: deleteModule,
            getAreas: getAreas
        };

        return ModuleService;

        function getAllModules() {
            return DataApiService.get('modulos');
        }

        function getModule(moduleId) {
            return DataApiService.get(`modulos/${moduleId}`);
        }

        function saveModule(module) {
            return DataApiService.post('modulos/', module);
        }

        function updateModule(module) {
            return DataApiService.put('modulos/', module);
        }

        function deleteModule(moduleId) {
            return DataApiService.delete(`modulos/${moduleId}`);
        }

        function getAreas() {
            return DataApiService.get('modulos/areas');
        }
    }
}());