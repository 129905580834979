(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('OrigensService', OrigensService)

    /** @ngInject */
    function OrigensService(DataApiService) {

        const OrigensService = {
            getAllOrigens: getAllOrigens,
            getOrigem: getOrigem,
            saveOrigem: saveOrigem,
            updateOrigem: updateOrigem,
            deleteOrigem: deleteOrigem
        };

        return OrigensService;

        function getAllOrigens() {
            return DataApiService.get('origens');
        }

        function getOrigem(origemId) {
            return DataApiService.get(`origens/${origemId}`)
        }

        function saveOrigem(origem) {
            return DataApiService.post('origens/', origem)
        }

        function updateOrigem(origem) {
            return DataApiService.put('origens/', origem);
        }

        function deleteOrigem(origemId) {
            return DataApiService.delete(`origens/${origemId}`);
        }
    }
}());