(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('ContatosService', ContatosService)

    /** @ngInject */
    function ContatosService(DataApiService) {

        const ContatosService = {
            getAll: getAll
        };

        return ContatosService;

        /**
         * 
         * @param {int} tipo - Quantidade
         */
        function getAll(tipo) {
            return DataApiService.get(`contatos/${tipo}`);
        }
    }
}());