(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('DataApiService', DataApiService)

    /** @ngInject */
    function DataApiService($http, EXTERNAL_ROUTES) {

        const DataApiService = {
            get: get,
            post: post,
            put: put,
            patch: patch,
            delete: apiDelete
        };

        return DataApiService;

        function get(url) {
            return $http.get(EXTERNAL_ROUTES.api + url).then(results => {
                if (results != null && results != undefined) {
                    return results.data;
                }

                return results;
            });
        }

        function post(url, object, config) {
            return $http
                .post(EXTERNAL_ROUTES.api + url, object, config)
                .then(results => {
                    if (results != null && results != undefined) {
                        return results.data;
                    }

                    return results;
                });
        }

        function put(url, object) {
            return $http
                .put(EXTERNAL_ROUTES.api + url, object)
                .then(results => {
                    if (results != null && results != undefined) {
                        return results.data;
                    }

                    return results;
                });
        }

        function patch(url, object) {
            return $http
                .patch(EXTERNAL_ROUTES.api + url, object)
                .then(results => {
                    if (results != null && results != undefined) {
                        return results.data;
                    }

                    return results;
                });
        }

        function apiDelete(url) {
            return $http.delete(EXTERNAL_ROUTES.api + url);
        }
    }
}());