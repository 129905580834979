(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .directive('cropImage', cropImageDirective);

    function cropImageDirective() {

        /** @ngInject */
        function CropImageController($scope, $rootScope, $timeout, DataApiService, HelpersService, NotificationService) {
            var vm = this;
            var attrs = {};
            var pasta = '';

            vm.cropData = {};
            vm.cropper = {};
            vm.cropperProxy = 'cropper';

            vm.options = {
                rotatable: false,
                aspectRatio: 0,
                crop: dataNew => {
                    $timeout(() => {
                        vm.cropData = dataNew;
                    });
                }
            };

            vm.startVars = pAttrs => {
                attrs = pAttrs;
                vm.options.aspectRatio = attrs.cropRatio || 0;

                if (attrs.cropArea)
                    pasta = attrs.cropArea;
            }

            vm.cropImage = () => {
                DataApiService
                    .post('files/crop', {
                        imagePath: attrs.cropArea + '\\' + vm.registroId,
                        imageName: vm.imageName,
                        width: Math.round(vm.cropData.width),
                        height: Math.round(vm.cropData.height),
                        startY: Math.round(vm.cropData.y),
                        startX: Math.round(vm.cropData.x)
                    })
                    .then(() => {
                        vm.image.display = HelpersService.caminhoPastaImagem(pasta, vm.registroId, 'thumb_' + vm.imageName + '?src=' + Math.random());
                        $('#modalCrop').modal('hide');
                        $scope.$broadcast('hideCropper');

                        NotificationService.notify({
                            title: 'SUCESSO',
                            text: 'Imagem salva!',
                            type: 'success'
                        });
                    }, () => {
                        NotificationService.notify({
                            title: 'OPS',
                            text: 'Ocorreu um erro inesperado, por favor tente novamente!',
                            type: 'error'
                        });
                    });
            }

            $rootScope.abreModal = () => {
                $('#modalCrop').modal('show');

                $scope.$broadcast('showCropper');
            }

            $scope.$on('$destroy', () => {
                $rootScope.abreModal = null;
            });
        }

        function link(scope, element, attrs, ctrl) {
            ctrl.startVars(attrs);
        }

        return {
            bindToController: true,
            controller: CropImageController,
            controllerAs: 'vm',
            replace: 'true',
            link: link,
            scope: {
                image: '=image',
                registroId: '=registroId',
                imageName: '=imageName',
                cropPasta: '=cropPasta'
            },
            templateUrl: 'angular/directives/crop-image/crop-image.html',
            restrict: 'AE'
        }
    }
}());