(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('BlogService', BlogService)

    /** @ngInject */
    function BlogService(DataApiService) {

        const BlogService = {
            getAllNews: getAllNews,
            getNews: getNews,
            updateNews: updateNews,
            saveNews: saveNews,
            deleteNews: deleteNews,
            getAllCategory: getAllCategory,
            getCategory: getCategory,
            updateCategory: updateCategory,
            saveCategory: saveCategory,
            deleteCategory: deleteCategory
        };

        return BlogService;
        
        function getAllNews(){
            return DataApiService.get('blog');
        }

        function getNews(newsId) {
            return DataApiService.get(`blog/${newsId}`);
        }

        function updateNews(news) {
            return DataApiService.put('blog', news);
        }

        function saveNews(news) {
            return DataApiService.post('blog', news);
        }

        function deleteNews(newsId) {
            return DataApiService.delete(`blog/${newsId}`);
        }

        function getAllCategory() {
            return DataApiService.get('blog/categorias');
        }

        function getCategory(categoryId) {
            return DataApiService.get(`blog/categorias/${categoryId}`);
        }

        function updateCategory(category) {
            return DataApiService.put('blog/categorias', category);
        }

        function saveCategory(category) {
            return DataApiService.post('blog/categorias', category);
        }

        function deleteCategory(categoryId) {
            return DataApiService.delete(`blog/categorias/${categoryId}`);
        }
    }
}());