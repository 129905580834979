(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('RedirectsService', RedirectsService)

    /** @ngInject */
    function RedirectsService(DataApiService) {

        const RedirectsService = {
            getAllRedirects: getAllRedirects,
            getRedirect: getRedirect,
            saveRedirect: saveRedirect,
            updateRedirect: updateRedirect,
            deleteRedirect: deleteRedirect
        };

        return RedirectsService;

        function getAllRedirects() {
            return DataApiService.get('redirects');
        }

        function getRedirect(redirectId) {
            return DataApiService.get(`redirects/${redirectId}`)
        }

        function saveRedirect(redirect) {
            return DataApiService.post('redirects/', redirect)
        }

        function updateRedirect(redirect) {
            return DataApiService.put('redirects/', redirect);
        }

        function deleteRedirect(redirectId) {
            return DataApiService.delete(`redirects/${redirectId}`);
        }
    }
}());