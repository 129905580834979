(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('NewsletterService', NewsletterService)

    /** @ngInject */
    function NewsletterService(DataApiService) {

        const NewsletterService = {
            getLast: getLast
        };

        return NewsletterService;

        function getLast() {
            return DataApiService.get('newsletters/last');
        }
    }
}());