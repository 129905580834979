(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('DataRepositorioService', DataRepositorioService)

    /** @ngInject */
    function DataRepositorioService($cookies) {

        const DataRepositorioService = {
            get: get,
            set: set,
            clear: clear
        };

        return DataRepositorioService;

        /**
         * Recupera os valores salvos no cache a partir do nome
         * @param {string} cacheLabel - Nome do cache a ser recuperado
         * @param {string} field - Nome do campo a ser recuperado, caso seja um objeto
         */
        function get(cacheLabel, field) {
            var cookieValue = $cookies.get(cacheLabel);
            var repositorioData = tryParseJSON(cookieValue);

            if (!repositorioData) {
                repositorioData = cookieValue;
            }

            if (!repositorioData || field == undefined || !angular.isObject(repositorioData)) {
                return repositorioData;
            }

            return repositorioData[field];
        }

        /**
         * Salva um valor no cache
         * @param {string} cacheLabel - Nome do cache a ser utilizado
         * @param {string} cacheData - Dados a serem salvos no cache
         * @param {int} cacheDays - Quantidade de dias até a expiração do cookie
         * @param {bool} shouldStringfy - Se deve converter os dados para json
         */
        function set(cacheLabel, cacheData, cacheDays = 1, shouldStringfy = true) {
            clear(cacheLabel);

            shouldStringfy = (shouldStringfy == null || shouldStringfy == undefined) ? true : shouldStringfy;
            cacheData = shouldStringfy ? JSON.stringify(cacheData) : cacheData;

            $cookies.put(cacheLabel, cacheData, {
                expires: new Date().addDays(cacheDays),
                path: '/'
            });
        }

        /**
         * Remove um cache a partir da label
         * @param {string} cacheLabel - Label do cache a ser removido
         */
        function clear(cacheLabel) {
            $cookies.remove(cacheLabel, {
                expires: new Date().addDays(-1)
            });
        }

        function tryParseJSON(jsonString) {
            try {
                var o = JSON.parse(jsonString);

                // Handle non-exception-throwing cases:
                // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
                // but... JSON.parse(null) returns null, and typeof null === "object",
                // so we must check for that, too. Thankfully, null is falsey, so this suffices:
                if (o && typeof o === 'object') {
                    return o;
                }
            } catch (e) {
                return false;
            }

            return false;
        }
    }
}());