(function () {
    'use strict';

    angular
        .module('5asecAdmin')
        .directive('stCustomSearch', StCustomSearch);

    /** @ngInject */
    function StCustomSearch() {
        function link(scope, element, attr, table) {
            scope.searchValue = '';

            var getPredicate = function () {
                var predicate = scope.predicate;
                if (!predicate && scope.predicateExpression) {
                    predicate = scope.predicateExpression;
                }
                return predicate;
            }

            scope.$watch('collection', function (newValue) {
                if (newValue) {
                    scope.optionChanged(scope.searchValue);
                }
            }, true)

            scope.predicateChanged = function () {
                scope.predicate = scope.selectedFilter;

                if (scope.searchValue) {
                    scope.optionChanged();
                }
            }

            scope.optionChanged = function () {
                var predicate = getPredicate() || {};
                var predicateValue = angular.isObject(predicate) ? predicate.value : predicate;

                var searchQuery = {};

                searchQuery.type = predicate.type || 'string';

                // if (predicate.type == 'date') {
                //     searchQuery.distinct = scope.searchValue || '';
                //     searchQuery.format = predicate.format;
                // }

                searchQuery.contains = scope.searchValue || '';

                // console.log('searchQuery', searchQuery);
                // console.log('searchValue', scope.searchValue);
                // console.log('predicateValue', predicateValue);
                // console.log('');

                if (!predicateValue) {
                    return;
                }

                table.search(searchQuery, predicateValue);
            }
        }

        return {
            require: '^stTable',
            restrict: 'EA',
            scope: {
                collection: '=',
                filters: '=',
                colspan: '='
            },
            templateUrl: 'angular/directives/st-custom-search/search.html',
            link: link
        }
    }
}());