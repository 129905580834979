(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .config(LazyLoadConfig)

    /** @ngInject */
    function LazyLoadConfig($ocLazyLoadProvider) {
        $ocLazyLoadProvider
            .config({
                debug: false,
                events: false,
                modules: [{
                    name: 'pnotify',
                    files: [
                        '/dist/plugins/pnotify/pnotify.min.css',
                        '/dist/plugins/pnotify/pnotify.min.js'
                    ]
                }, {
                    name: 'sweetAlert',
                    files: [
                        '/dist/plugins/sweet-alert/sweetalert.min.js',
                        '/dist/plugins/sweet-alert/sweetalert.angular.min.js',
                        '/dist/plugins/sweet-alert/sweetalert.css'
                    ]
                }, {
                    name: 'angularValidator',
                    files: ['/dist/plugins/angular-validator/angular-validator.min.js']
                }, {
                    name: 'smart-table',
                    files: ['/dist/plugins/smart-table/smart-table.js']
                }, {
                    name: 'aceEditor',
                    files: [
                        '/dist/plugins/angular-ace/aces.js',
                        '/dist/plugins/angular-ace/ui-aces.js'
                    ],
                    serie: true
                }, {
                    name: 'cropper',
                    files: [
                        '/dist/plugins/cropper/ngCropper.all.min.js',
                        '/dist/plugins/cropper/ngCropper.all.min.css'
                    ],
                    serie: true
                }, {
                    name: 'moment',
                    files: [
                        '/dist/plugins/moment/moment.min.js',
                        '/dist/plugins/moment/moment-locale-pt-br.js'
                    ],
                    serie: true
                }, {
                    name: 'angularFilemanager',
                    files: [

                        '/dist/plugins/angular-filemanager/angular-filemanager.min.css',
                        '/dist/plugins/angular-filemanager/angular-translate.min.js',
                        '/dist/plugins/angular-filemanager/ng-file-upload-shim.min.js',
                        '/dist/plugins/angular-filemanager/ng-file-upload-all.min.js',
                        '/dist/plugins/angular-filemanager/angular-filemanager.min.js'
                    ],
                    serie: true
                }, {
                    name: 'datepicker',
                    files: [
                        '/dist/plugins/bootstrap-datepicker/css/datepicker3.css',
                        '/dist/plugins/bootstrap-datepicker/js/bootstrap-datepicker.js',
                        '/dist/plugins/bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR.js'
                    ],
                    serie: true
                }, {
                    name: 'daterangepicker',
                    files: [
                        '/dist/plugins/moment/moment.min.js',
                        '/dist/plugins/bootstrap-daterangepicker/daterangepicker-bs3.css',
                        '/dist/plugins/bootstrap-daterangepicker/daterangepicker.js',
                        '/dist/plugins/angular-daterangepicker/angular-daterangepicker.min.js'
                    ],
                    serie: true
                }, {
                    name: 'timepicker',
                    files: [
                        '/dist/plugins/bootstrap-timepicker/bootstrap-timepicker.min.css',
                        '/dist/plugins/bootstrap-timepicker/bootstrap-timepicker.min.js'
                    ]
                }, {
                    name: 'inputMask',
                    files: ['/dist/plugins/jquery-inputmask/jquery.inputmask.min.js']
                }, {
                    name: 'tagsInput',
                    files: [
                        '/dist/plugins/angular-tags-input/tags-input.css',
                        '/dist/plugins/angular-tags-input/tags-input.min.js'
                    ]
                }, {
                    name: 'typehead',
                    files: ['/dist/plugins/bootstrap-typehead/typeahead.bundle.min.js']
                }, {
                    name: 'materialPicker',
                    files: ['/dist/plugins/angular-material-picker/ui.bootstrap.materialPicker.js']
                }, {
                    name: 'lodash',
                    files: ['/dist/plugins/lodash/lodash.min.js']
                }, {
                    name: 'flowJs',
                    files: [
                        '/dist/plugins/flow/flow.js',
                        '/dist/plugins/flow/ng-flow.js'
                    ],
                    serie: true
                }, {
                    name: 'angularToolTips',
                    files: [
                        '/dist/plugins/angular-tooltips/angular-tooltips.min.js',
                        '/dist/plugins/angular-tooltips/angular-tooltips.min.css'
                    ]
                }, {
                    name: 'materialPicker',
                    files: [
                        '/dist/plugins/angular-material-picker/ui.bootstrap.materialPicker.js'
                    ]
                }]
            })
    }
}());