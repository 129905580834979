(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('AuthService', AuthService)

    /** @ngInject */
    function AuthService($q, $http, $location, $httpParamSerializerJQLike, jwtHelper, DataRepositorioService, SweetAlert, AUTH_EVENTS, EXTERNAL_ROUTES) {

        const AuthService = {
            isAuthenticated: isAuthenticated,
            doLogin: doLogin,
            doLogout: doLogout,
            doRecoverPassword: doRecoverPassword,
            notAuthorized: notAuthorized
        };

        return AuthService;

        function isAuthenticated() {
            const userToken = DataRepositorioService.get('userToken');
            const userProfile = DataRepositorioService.get('userProfile');

            if (!userProfile || !userToken) {
                return false;
            }

            if (jwtHelper.isTokenExpired(userToken)) {
                return false;
            }

            return true;
        }

        function doLogin(email, password, remember) {
            const user = {
                UserName: email,
                Password: Encrypt.Hash(password).toString(),
                'grant_type': 'password'
            };

            var defer = $q.defer();

            $http
                .post(EXTERNAL_ROUTES.api + 'login', $httpParamSerializerJQLike(user), {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                .then((success) => {
                    handleUserToken(success.data.access_token, remember);

                    defer.resolve(true);
                }, (error) => {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function doRecoverPassword(email) {
            return $http
                .post(EXTERNAL_ROUTES.api + 'usuarios/recuperar-senha/' + email);
        }

        function handleUserToken(token, remember) {
            // var cookieTime = remember ? 5 : 1;
            const userData = jwtHelper.decodeToken(token);

            DataRepositorioService.set('userToken', token, 1, false);
            DataRepositorioService.set('userProfile', userData);
        }

        function doLogout(shouldRedir = true) {
            DataRepositorioService.clear('userToken');
            DataRepositorioService.clear('userProfile');

            if (shouldRedir) {
                window.location.href = '/login?ReturnUrl=' + $location.path();
            }
        }

        function notAuthorized() {
            SweetAlert
                .swal({
                    title: 'OPS',
                    text: 'Você não está autorizado a acessar essa área!',
                    type: 'error',
                    confirmButtonColor: '#eea236',
                    confirmButtonText: 'Sair',
                    closeOnConfirm: false
                }, (isConfirm) => {
                    if (isConfirm) {
                        $state.go('main.dashboard');
                    }
                });
        }
    }
}());