(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .filter('getByProperty', getByProperty)
        .filter('replaceAll', replaceAll)
        .filter('round', round)
        .filter('ceil', ceil)
        .filter('indexOf', indexOf)
        .filter('CEP', CEP)
        .filter('phoneBR', phoneBR)
        .filter('smartTableFilter', smartTableFilter)
        .filter('filtraMenuPermissao', filtraMenuPermissao);

    function getByProperty() {
        return (propertyName, propertyValue, collection) => {
            if (!collection) {
                return;
            }

            var i = 0,
                len = collection.length;
            for (; i < len; i++) {
                if (collection[i][propertyName] == propertyValue) {
                    return collection[i];
                }
            }
            return null;
        }
    }

    function replaceAll() {
        return (input, str1, str2, ignore) => {
            if (!input) {
                return input;
            }

            return input.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '\\$&'), (ignore ? 'gi' : 'g')), (typeof(str2) == 'string') ? str2.replace(/\$/g, '$$$$') : str2);
        }
    }

    function round() {
        return input => {
            if (!input) {
                return;
            }

            if (angular.isNumber(input)) {
                return Math.round(+input);
            }

            return input;
        }
    }

    function ceil() {
        return input => {
            if (!input) {
                return;
            }

            if (angular.isNumber(input)) {
                return Math.ceil(+input);
            }

            return input;
        }
    }

    function indexOf() {
        return (input, search) => {
            if (input == undefined || search == undefined) {
                false;
            }

            return input.indexOf(search) >= 0;
        }
    }
    
    function CEP (){ 
        return input => { 
            var str = input + ''; 
            str = str.replace(/\D/g, ''); 
            str = str.replace(/^(\d{2})(\d{3})(\d)/, '$1.$2-$3'); 
            return str; 
        } 
    } 
 
    function phoneBR() { 
        return input => { 
            var str = input + ''; 
            str = str.replace(/\D/g, ''); 
            if (str.length === 11) { 
                str = str.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); 
            } else { 
                str = str.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'); 
            } 
            return str; 
        } 
    } 

    /** ngInject */
    function smartTableFilter($filter) {
        var filterFilter = $filter('filter');

        var searchContains = function(value, search) {
            search = ('' + search).toLowerCase();
            return ('' + value).toLowerCase().indexOf(search) > -1;
        }

        var searchDistinct = function(value, search) {
            if (!value || !search || value.toLowerCase() !== search.toLowerCase()) {
                return false;
            }

            return true;
        }

        var formatDate = function isDataValida(date, format) {
            return $filter('date')(new Date(date), format);
        }

        return function customFilter(array, expression) {
            function customComparator(actual, expected) {
                // console.log('actual', actual);
                // console.log('expected', expected);
                // console.log('');

                if (angular.isObject(expected)) {
                    // if (expected.type == 'date') {
                    //     if (expected.distinct && expected.format) {
                    //         actual = formatDate(expected.distinct, expected.format);
                    //     }

                    //     if (expected.contains && expected.format) {
                    //         actual = formatDate(expected.contains, expected.format);
                    //     }
                    // }

                    if (expected.distinct) {
                        return searchDistinct(actual, expected.distinct);
                    }

                    if (expected.contains) {
                        return searchContains(actual, expected.contains);
                    }

                    return true;
                }

                return searchContains(actual, expected);
            }

            var output = filterFilter(array, expression, customComparator);
            return output;
        };
    }

    function filtraMenuPermissao() {
        return (menuModules, menuItem) => { 
            return menuModules.filter(function(item, index, arr) {
                if(!menuItem.PermissaoUsuario) {
                    return item.Nome.toLowerCase().indexOf('novo') == -1 && item.Nome.toLowerCase().indexOf('nova') == -1;
                } else {
                    return true;
                }
            });
        } 
    }
}());