(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .directive('sidebar', SidebarDirective);

    function SidebarDirective() {

        /** @ngInject */
        function SidebarController($scope, NavigationService) {
            init();

            function init() {
                NavigationService.getMenu();
            }

            this.handleJQueryEvents = function(element) {
                var $sidebar = $('[pg-sidebar]', element);
                $sidebar.sidebar($sidebar.data());

                // Bind events
                // Toggle sub menus
                $('body').on('click', '.sidebar-menu a', function() {
                    if ($(this).parent().children('.sub-menu') === false) {
                        return;
                    }

                    var el = $(this);
                    var li = $(this).parent();
                    var parent = $(this).parent().parent();
                    var sub = $(this).parent().children('.sub-menu');

                    if (li.hasClass('active open')) {
                        el.children('.arrow').removeClass('active open');
                        sub.slideUp(200, function() {
                            li.removeClass('active open');
                        });
                    } else {
                        parent.children('li.open').children('.sub-menu').slideUp(200);
                        parent.children('li.open').children('a').children('.arrow').removeClass('active open');
                        parent.children('li.open').removeClass('open active');
                        el.children('.arrow').addClass('active open');
                        sub.slideDown(200, function() {
                            li.addClass('active open');

                        });
                    }
                });

                $scope.$on('$destroy', function() {
                    $('.sidebar-menu a').off();
                });
            }
        }

        function link(scope, element, attrs, ctrl) {
            ctrl.handleJQueryEvents(element);
        }

        return {
            bindToController: true,
            controller: SidebarController,
            controllerAs: 'vm',
            restrict: 'AE',
            link: link,
            templateUrl: 'angular/directives/sidebar/sidebar.html',
            scope: false
        }
    }
}());