(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('LeadsService', LeadsService)

    /** @ngInject */
    function LeadsService(DataApiService) {

        const LeadsService = {
            getAllLeads: getAllLeads,
            getReportResume: getReportResume,
            getReport: getReport
        };

        return LeadsService;

        function getAllLeads() {
            return DataApiService.get('leads');
        }

        function getReportResume(dataInicio, dataFim) {
            return DataApiService.get(`integracao/ecommerce/reports/resume/${dataInicio}/${dataFim}`);
        }

        function getReport(dataInicio, dataFim) {
            return DataApiService.get(`integracao/ecommerce/reports/${dataInicio}/${dataFim}`);
        }
    }
}());