(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .config(routes);

    /** @ngInject */
    function routes($stateProvider, $urlRouterProvider, $locationProvider, $ocLazyLoadProvider, BASE_PATHS, DeviceTypeProvider) {
        // var urlMobilePrepend = DeviceTypeProvider.isMobileDevice ? '.mobile' : '';
        var insertBefore = {
            insertBefore: '#lazyload_placeholder'
        };

        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('/login');

        $stateProvider
            //Login
            .state('login', {
                url: '/login',
                templateUrl: BASE_PATHS.files + 'login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'login/login.controller.js']);
                                });
                        }
                    ]
                }
            })

            .state('main', {
                abstract: true,
                templateUrl: BASE_PATHS.templateFiles + 'app.html'
            })

            //Home
            .state('main.dashboard', {
                url: '/home',
                templateUrl: BASE_PATHS.files + 'dashboard/dashboard.html',
                controller: 'DashboardController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load([], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'dashboard/dashboard.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Modulos
            .state('main.modulos', {
                template: '<div ui-view></div>'
            })
            .state('main.modulos.listar', {
                url: '/modulos',
                templateUrl: BASE_PATHS.files + 'modulos/modulos-lista.html',
                controller: 'ModulosListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'modulos/modulos-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.modulos.novo', {
                url: '/modulos/novo',
                templateUrl: BASE_PATHS.files + 'modulos/modulos-form.html',
                controller: 'ModulosFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'modulos/modulos-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.modulos.editar', {
                url: '/modulos/{moduleId:int}',
                templateUrl: BASE_PATHS.files + 'modulos/modulos-form.html',
                controller: 'ModulosFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'modulos/modulos-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Blog
            .state('main.blog', {
                template: '<div ui-view></div>'
            })
            .state('main.blog.listar', {
                url: '/blog',
                templateUrl: BASE_PATHS.files + 'blog/blog-lista.html',
                controller: 'BlogListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'blog/blog-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.blog.novo', {
                url: '/blog/novo',
                templateUrl: BASE_PATHS.files + 'blog/blog-form.html',
                controller: 'BlogFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'flowJs', 'cropper'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'blog/blog-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.blog.editar', {
                url: '/blog/{newsId:int}',
                templateUrl: BASE_PATHS.files + 'blog/blog-form.html',
                controller: 'BlogFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'flowJs', 'cropper'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'blog/blog-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.blog.categoria-listar', {
                url: '/blog/categorias',
                templateUrl: BASE_PATHS.files + 'blog/blog-categorias-lista.html',
                controller: 'BlogCategoriasListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'blog/blog-categorias-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.blog.categoria-nova', {
                url: '/blog/categorias/nova',
                templateUrl: BASE_PATHS.files + 'blog/blog-categorias-form.html',
                controller: 'BlogCategoriasFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'materialPicker', 'flowJs'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'blog/blog-categorias-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.blog.categoria-editar', {
                url: '/blog/categorias/{categoryId:int}',
                templateUrl: BASE_PATHS.files + 'blog/blog-categorias-form.html',
                controller: 'BlogCategoriasFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'materialPicker', 'flowJs'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'blog/blog-categorias-form.controller.js']);
                                });
                        }
                    ]
                }
            })


            //usuarios
            .state('main.usuarios', {
                template: '<div ui-view></div>'
            })
            .state('main.usuarios.perfil', {
                url: '/meu-perfil',
                templateUrl: BASE_PATHS.files + 'usuarios/usuarios-perfil.html',
                controller: 'UsuarioPerfilController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'usuarios/usuarios-perfil.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.usuarios.listar', {
                url: '/usuarios',
                templateUrl: BASE_PATHS.files + 'usuarios/usuarios-lista.html',
                controller: 'UsuariosListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'usuarios/usuarios-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.usuarios.novo', {
                url: '/usuarios/novo',
                templateUrl: BASE_PATHS.files + 'usuarios/usuarios-form.html',
                controller: 'UsuariosFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'usuarios/usuarios-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.usuarios.editar', {
                url: '/usuarios/{userId:int}',
                templateUrl: BASE_PATHS.files + 'usuarios/usuarios-form.html',
                controller: 'UsuariosFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'usuarios/usuarios-form.controller.js']);
                                });
                        }
                    ]
                }
            })


            //CTA
            .state('main.cta', {
                template: '<div ui-view></div>'
            })
            .state('main.cta.listar', {
                url: '/cta',
                templateUrl: BASE_PATHS.files + 'cta/cta-lista.html',
                controller: 'CtaListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'cta/cta-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.cta.nova', {
                url: '/cta/nova',
                templateUrl: BASE_PATHS.files + 'cta/cta-form.html',
                controller: 'CtaFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'cta/cta-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.cta.editar', {
                url: '/cta/{ctaId:int}',
                templateUrl: BASE_PATHS.files + 'cta/cta-form.html',
                controller: 'CtaFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'cta/cta-form.controller.js']);
                                });
                        }
                    ]
                }
            })


            //Leads
            .state('main.leads', {
                template: '<div ui-view></div>'
            })
            .state('main.leads.listar', {
                url: '/leads',
                templateUrl: BASE_PATHS.files + 'leads/leads-lista.html',
                controller: 'LeadsListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'leads/leads-lista.controller.js']);
                                });
                        }
                    ]
                }
            })


            //Mídias
            .state('main.midias', {
                template: '<div ui-view></div>'
            })
            .state('main.midias.listar', {
                url: '/midias',
                templateUrl: BASE_PATHS.files + 'midias/midias-lista.html',
                controller: 'MidiasListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'midias/midias-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.midias.nova', {
                url: '/midias/nova',
                templateUrl: BASE_PATHS.files + 'midias/midias-form.html',
                controller: 'MidiasFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'midias/midias-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.midias.editar', {
                url: '/midias/{midiaId:int}',
                templateUrl: BASE_PATHS.files + 'midias/midias-form.html',
                controller: 'MidiasFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'midias/midias-form.controller.js']);
                                });
                        }
                    ]
                }
            })


            //Paginas
            .state('main.paginas', {
                template: '<div ui-view></div>'
            })
            .state('main.paginas.listar', {
                url: '/paginas',
                templateUrl: BASE_PATHS.files + 'paginas/paginas-lista.html',
                controller: 'PaginasListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'paginas/paginas-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.paginas.novo', {
                url: '/paginas/nova',
                templateUrl: BASE_PATHS.files + 'paginas/paginas-form.html',
                controller: 'PaginasFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'paginas/paginas-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.paginas.editar', {
                url: '/paginas/{pageId:int}',
                templateUrl: BASE_PATHS.files + 'paginas/paginas-form.html',
                controller: 'PaginasFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'paginas/paginas-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Newsletters
            .state('main.newsletters', {
                template: '<div ui-view></div>'
            })
            .state('main.newsletters.listar', {
                url: '/newsletters',
                templateUrl: BASE_PATHS.files + 'newsletters/newsletters-lista.html',
                controller: 'NewslettersListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'newsletters/newsletters-lista.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Mídias
            .state('main.origens', {
                template: '<div ui-view></div>'
            })
            .state('main.origens.listar', {
                url: '/origens',
                templateUrl: BASE_PATHS.files + 'origens/origens-lista.html',
                controller: 'OrigensListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'origens/origens-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.origens.nova', {
                url: '/origens/nova',
                templateUrl: BASE_PATHS.files + 'origens/origens-form.html',
                controller: 'OrigensFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'origens/origens-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.origens.editar', {
                url: '/origens/{origemId:int}',
                templateUrl: BASE_PATHS.files + 'origens/origens-form.html',
                controller: 'OrigensFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'origens/origens-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Redirects
            .state('main.redirects', {
                template: '<div ui-view></div>'
            })
            .state('main.redirects.listar', {
                url: '/redirects',
                templateUrl: BASE_PATHS.files + 'redirects/redirects-lista.html',
                controller: 'RedirectsListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'redirects/redirects-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.redirects.novo', {
                url: '/redirects/novo',
                templateUrl: BASE_PATHS.files + 'redirects/redirects-form.html',
                controller: 'RedirectsFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'redirects/redirects-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.redirects.editar', {
                url: '/redirects/{redirectId:int}',
                templateUrl: BASE_PATHS.files + 'redirects/redirects-form.html',
                controller: 'RedirectsFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'redirects/redirects-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Seo
            .state('main.seo', {
                template: '<div ui-view></div>'
            })
            .state('main.seo.lista', {
                url: '/seo',
                templateUrl: BASE_PATHS.files + 'seo/seo-lista.html',
                controller: 'SeoListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'seo/seo-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.seo.editar', {
                url: '/seo/{seoId:int}',
                templateUrl: BASE_PATHS.files + 'seo/seo-form.html',
                controller: 'SeoFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'flowJs', 'cropper', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'seo/seo-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Seo Geral
            .state('main.seo-geral', {
                template: '<div ui-view></div>'
            })
            .state('main.seo-geral.lista', {
                url: '/seo-geral',
                templateUrl: BASE_PATHS.files + 'seo/seo-geral-lista.html',
                controller: 'SeoGeralListaController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'seo/seo-geral-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.seo-geral.novo', {
                url: '/seo-geral/novo',
                templateUrl: BASE_PATHS.files + 'seo/seo-geral-form.html',
                controller: 'SeoGeralFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'seo/seo-geral-form.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.seo-geral.editar', {
                url: '/seo-geral/{seoGeralId:int}',
                templateUrl: BASE_PATHS.files + 'seo/seo-geral-form.html',
                controller: 'SeoGeralFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'aceEditor'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'seo/seo-geral-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Sistema
            .state('main.sistema', {
                template: '<div ui-view></div>'
            })
            .state('main.sistema.status-servico', {
                url: '/status-servico',
                templateUrl: BASE_PATHS.files + 'sistema/status-servico.html',
                controller: 'StatusServicoController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'sistema/status-servico.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Hotsites
            .state('main.hotsites', {
                template: '<div ui-view></div>'
            })
            .state('main.hotsites.lista', {
                url: '/hotsites',
                templateUrl: BASE_PATHS.files + 'hotsites/hotsites.html',
                controller: 'HotsitesController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify', 'smart-table'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'hotsites/hotsites.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.hotsites.editar', {
                url: '/hotsites/{hotsiteId:int}',
                templateUrl: BASE_PATHS.files + 'hotsites/hotsites-form.html',
                controller: 'HotsitesFormController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'hotsites/hotsites-form.controller.js']);
                                });
                        }
                    ]
                }
            })

            //Fale Conosco
            .state('main.fale', {
                template: '<div ui-view></div>'
            })
            .state('main.fale-site', {
                url: '/fale/site',
                templateUrl: BASE_PATHS.files + 'fale-conosco/fale-site-lista.html',
                controller: 'FaleSiteController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'fale-conosco/fale-site-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.fale-unidade', {
                url: '/fale/unidade',
                templateUrl: BASE_PATHS.files + 'fale-conosco/fale-unidade-lista.html',
                controller: 'FaleUnidadeController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'fale-conosco/fale-unidade-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
            .state('main.fale-franqueado', {
                url: '/fale/franqueado',
                templateUrl: BASE_PATHS.files + 'fale-conosco/fale-franqueado-lista.html',
                controller: 'FaleFranqueadoController',
                controllerAs: 'vm',
                resolve: {
                    deps: [
                        '$ocLazyLoad',
                        function($ocLazyLoad) {
                            return $ocLazyLoad
                                .load(['smart-table', 'pnotify'], insertBefore)
                                .then(function() {
                                    return $ocLazyLoad.load([BASE_PATHS.files + 'fale-conosco/fale-franqueado-lista.controller.js']);
                                });
                        }
                    ]
                }
            })
    }
}());