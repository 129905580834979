(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .directive('sitemap', SitemapDirective);

    /** @ngInject */
    function SitemapDirective(SitemapService, NotificationService, SweetAlert) {

        function SitemapController() {
            var vm = this;

            vm.confirmGenerate = function() {
                SweetAlert.swal({
                    title: 'Você tem certeza?',
                    text: 'Essa ação também irá atualizar o cache de rotas do site!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#4caf50',
                    confirmButtonText: 'Sim, atualizar!',
                    cancelButtonText: 'Não, cancele!',
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, (isConfirm) => {
                    if (isConfirm) {
                        generateSitemaps();
                    }
                });
            }

            function generateSitemaps() {
                SitemapService
                    .criaSitemaps()
                    .then(
                        (successData) => {
                            NotificationService.notify({
                                title: 'SUCESSO',
                                text: 'Os sitemaps foram atualizados!',
                                type: 'success'
                            });
                        }, (errorData) => {
                            console.log('errorData', errorData);

                            NotificationService.notify({
                                title: 'OPS',
                                text: 'Ocorreu um erro inesperado, por favor tente novamente!',
                                type: 'error'
                            });
                        }
                    )
            }
        }

        function link(scope, $element, attrs, ctrl) {
            $element.bind('click', () => {
                ctrl.confirmGenerate();
            });
        }

        return {
            bindToController: true,
            controller: SitemapController,
            controllerAs: 'vm',
            restrict: 'AEC',
            link: link,
            templateUrl: 'angular/directives/sitemap/sitemap.html',
            scope: {}
        }
    }

}());