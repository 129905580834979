(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .factory('ValidationMessagesService', ValidationMessagesService);

    /** @ngInject */
    function ValidationMessagesService() {

        const ValidationMessagesService = {
            returnMessage: returnMessage
        };

        return ValidationMessagesService;

        function returnMessage(tipo) {
            var mensagens = {
                required: 'Este campo é requerido.',
                email: 'Por favor, forneça um endereço de email válido.',
                date: 'Por favor, forneça uma data válida.',
                number: 'Por favor, forneça um número válido.',
                url: 'Por favor, forneça uma url válida.',
                passNotMatch: 'As senhas não combinam',
                passTooSmall: 'A senha deve conter ao menos 8 caracteres',
                passNoUpper: 'A senha deve conter ao menos um caracter maiúsculo',
                passNoNumeric: 'A senha deve conter ao menos um caracter numérico'
            };
            return mensagens[tipo];
        }
    }
}());