(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('NavigationService', NavigationService)

    /** @ngInject */
    function NavigationService($rootScope, $q, $location, $timeout, $state, UserService, DataApiService) {

        $rootScope.menu = null;

        var menuLoadDataStarted = false;
        var deferMenu = $q.defer();
        var openItems = [];
        var selectedItems = [];
        var highlightedItems = [];
        var selectedFromNavMenu = false;

        const NavigationService = {
            setParent: setParent,
            getMenu: getMenu,
            findItemByUrl: findItemByUrl,
            select: select,
            highlight: highlight,
            highlightItems: highlightItems,
            setSelectedMenu: setSelectedMenu,
            handleChangeSuccess: handleChangeSuccess
        };

        return NavigationService;

        function setParent(children, parent) {
            angular.forEach(children, function(child) {
                child.parent = parent;

                if (child.SubModulos !== undefined && child.SubModulos.length) {
                    setParent(child.SubModulos, child);
                }
            });
        }

        function getMenu() {
            if (!$rootScope.menu && !menuLoadDataStarted && $location.path().indexOf('login') == -1) {
                menuLoadDataStarted = true;

                UserService
                    .loadUsuario()
                    .then(() => {
                        DataApiService
                            .get('modulos/usuarios/' + UserService.usuario.Id)
                            .then((successData) => {

                                var homeMenu = {
                                    Nome: 'Home',
                                    Icone: 'pg-home',
                                    IconBgClass: 'bg-success text-black',
                                    Url: '/home',
                                    SubModulos: []
                                };

                                successData
                                    .sort(function(a, b) {
                                        return (a.Nome > b.Nome) ? 1 : ((b.Nome > a.Nome) ? -1 : 0);
                                    })
                                    .unshift(homeMenu);

                                setParent(successData, null);

                                $rootScope.menu = successData;

                                window.loading_screen.finish();

                                deferMenu.resolve();
                            }, (errorData) => {
                                console.log('error', errorData);
                            });
                    });
            } else {
                return deferMenu.promise;
            }

            return deferMenu.promise;
        }

        function findItemByUrl(children, url) {
            for (var i = 0, length = children.length; i < length; i++) {
                if (children[i].Url && children[i].Url.indexOf(url) >= 0) {
                    return children[i];
                }

                if (children[i].SubModulos !== undefined && children[i].SubModulos.length) {
                    var item = findItemByUrl(children[i].SubModulos, url);

                    if (item) {
                        return item;
                    }
                }
            }
        }

        function setPermission(item) {
            var permItem = item;

            if(!permItem.PermissaoUsuario && item.parent) {
                permItem = item.parent;
            } else {
                $rootScope.canEditModule = false;
            }

            $rootScope.canEditModule = permItem.PermissaoUsuario == 1;
        }

        function select(item) {
            setPermission(item);

            for (var i = openItems.length - 1; i >= 0; i--) {
                var el = $('.' + openItems[i].Nome.replaceAll('.', ''));
                var li = $('.' + openItems[i].Nome.replaceAll('.', '')).parent();

                if (li.hasClass('open active')) {
                    el.children('.arrow').removeClass('open active');
                    sub.slideUp(200, function() {
                        li.removeClass('open active');

                        // close open nodes
                        if (item.open) {
                            item.open = false;
                        }
                    });
                }

                if (selectedFromNavMenu == false) {
                    openItems[i].open = false;
                }
            }

            openItems = [];

            var parentRef = item;

            while (parentRef !== null) {
                parentRef.open = true;
                openItems.push(parentRef);
                parentRef = parentRef.parent;
            }

            // handle leaf nodes
            if (item.SubModulos == undefined || (item.SubModulos && item.SubModulos.length < 1)) {
                selectedFromNavMenu = true;

                for (var j = selectedItems.length - 1; j >= 0; j--) {
                    selectedItems[j].selected = false;
                }

                selectedItems = [];
                parentRef = item;

                while (parentRef !== null) {
                    parentRef.selected = true;
                    selectedItems.push(parentRef);
                    parentRef = parentRef.parent;
                }
            }
        }

        function highlight(item) {
            var parentRef = item;

            while (parentRef !== null) {
                if (parentRef.selected) {
                    parentRef = null;
                    continue;
                }

                parentRef.selected = true;
                highlightedItems.push(parentRef);
                parentRef = parentRef.parent;
            }
        }

        function highlightItems(children, query) {
            angular.forEach(children, function(child) {
                if (child.Nome.toLowerCase().indexOf(query) > -1) {
                    this.highlight(child);
                }

                if (child.SubModulos !== undefined && child.SubModulos.length) {
                    this.highlightItems(child.SubModulos, query);
                }
            });
        }

        function setSelectedMenu() {
            var item;
            var path = $location.path()
                .substring(1)
                .split('/');

            if (!$rootScope.menu || $location.path() == '/meu-perfil') {
                return;
            }

            if (!isNaN(path[path.length - 1])) {
                item = findItemByUrl($rootScope.menu, '/' + path.join('/').slice(0, -1));
            } else {
                item = findItemByUrl($rootScope.menu, $location.path());
            }

            if (item) {
                $timeout(function() {
                    select(item);
                });
            } else if (path.length == 3) {
                item = findItemByUrl($rootScope.menu, '/' + path[1]);
                path = $location.path()
                    .substring(1)
                    .split('/');

                if (item) {
                    $timeout(function() {
                        select(item);
                    });
                } else {
                    $state.go('main.dashboard');
                }
            } else {
                item = findItemByUrl($rootScope.menu, '/' + path[0]);
                path = $location.path()
                    .substring(1)
                    .split('/');

                if (item) {
                    $timeout(function() {
                        select(item);
                    });
                } else {
                    $state.go('main.dashboard');
                }
            }

            selectedFromNavMenu = false;
        }

        function handleChangeSuccess() {
            this.getMenu()
                .then(() => {
                    NavigationService.setSelectedMenu();
                });
        }
    }
}());