(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('HotsiteService', HotsiteService)

    /** @ngInject */
    function HotsiteService(DataApiService) {

        const HotsiteService = {
            getHotsite: getHotsite,
            getAllSimplificado: getAllSimplificado,
            update: update
        };

        return HotsiteService;

        function getHotsite(hotsiteId) {
            return DataApiService.get(`hotsites/${hotsiteId}`);
        }

        function getAllSimplificado() {
            return DataApiService.get('hotsites/simplificado');
        }

        function update(hotsite) {
            return DataApiService.put('hotsites/', hotsite);
        }
    }
}());