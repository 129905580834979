(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('CtaService', CtaService)

    /** @ngInject */
    function CtaService(DataApiService) {

        const CtaService = {
            getAllCta: getAllCta,
            getCta: getCta,
            saveCta: saveCta,
            updateCta: updateCta,
            deleteCta: deleteCta
        };

        return CtaService;

        function getAllCta() {
            return DataApiService.get('cta');
        }

        function getCta(ctaId) {
            return DataApiService.get(`cta/${ctaId}`);
        }

        function saveCta(cta) {
            return DataApiService.post('cta/', cta);
        }

        function updateCta(cta) {
            return DataApiService.put('cta/', cta);
        }

        function deleteCta(ctaId) {
            return DataApiService.delete(`cta/${ctaId}`);
        }
    }
}());