(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .factory('AuthInterceptorService', AuthInterceptorService)

    /** @ngInject */
    function AuthInterceptorService($rootScope, $q, jwtHelper, DataRepositorioService, AUTH_EVENTS) {

        const AuthInterceptorService = {
            request: request,
            responseError: responseError
        };

        return AuthInterceptorService;

        function request(config) {
            const token = DataRepositorioService.get('userToken');

            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }

            return config;
        }

        function responseError(response) {
            $rootScope.$broadcast({
                401: AUTH_EVENTS.notAuthenticated
            }[response.status], response);

            return $q.reject(response);
        }
    }
}());