(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('NotificationService', NotificationService)

    /** @ngInject */
    function NotificationService($window, $timeout, HelpersService) {
        var pNotify = {
            count: 0
        };

        const service = {
            formatMessage: formatMessage,
            notify: notify,
            errorMessage: errorMessage,
            updateMessage: updateMessage,
            createMessage: createMessage,
            deleteMessage: deleteMessage
        };

        return service;

        function formatMessage(originalMessage) {
            var messages = '';

            if (originalMessage.validation) {
                messages += '<ul>';

                angular.forEach(originalMessage, function(value) {
                    messages += '<li>' + value.message + '</li>';
                });

                messages += '</ul>';
            } else if (angular.isArray(originalMessage)) {
                if (originalMessage.length == 1) {
                    return originalMessage[0];
                }

                messages += '<ul>';

                angular.forEach(originalMessage, function(value) {
                    messages += '<li>' + value + '</li>';
                });

                messages += '</ul>';
            } else {
                messages += originalMessage;
            }

            return messages
        }

        function notify(args) {
            $timeout(function() {
                args['mouse_reset'] = false;
                args.styling = 'fontawesome';
                args.text = formatMessage(args.text);

                if (pNotify.count >= 2) {
                    pNotify.count = 0;
                    $window.PNotify && $window.PNotify.removeAll();
                }

                var notification = new $window.PNotify(args);
                notification.notify = notification.update;

                pNotify.count++;

                return notification;
            }, 10);
        }

        function errorMessage(error = {
            data: ''
        }) {
            let message = '';

            if (error.data && error.data.ErrorCode) {
                if (error.data.ErrorCode == 404 || error.data.ErrorCode == 409) {

                    message = error.data.ErrorMessage || '';

                } else if (error.data.ErrorCode == 422) {
                    var messages = HelpersService.tryParseJSON(error.data.ErrorMessage);

                    if (messages) {
                        for (var i = 0; i < messages.length; i++) {
                            message += messages[i].ErrorMessage;
                        }
                    } else {
                        message = 'Ocorreu um erro inesperado, por favor tente novamente!';
                    }
                }
            } else {
                message = 'Ocorreu um erro inesperado, por favor tente novamente!';
            }

            notify({
                title: 'OPS',
                text: message,
                type: 'error'
            });
        }

        function updateMessage() {
            notify({
                title: 'SUCESSO',
                text: 'O registro foi alterado com sucesso!',
                type: 'success'
            });
        }

        function createMessage() {
            notify({
                title: 'SUCESSO',
                text: 'O registro foi criado com sucesso!',
                type: 'success'
            });
        }

        function deleteMessage() {
            notify({
                title: 'SUCESSO',
                text: 'O registro foi apagado!',
                type: 'success'
            });
        }
    }
}());