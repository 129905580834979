(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('HelpersService', HelpersService)

    /** @ngInject */
    function HelpersService($rootScope, $q, $window, $location, LoadingPanelService, AuthService, NavigationService, DataRepositorioService, BASE_PATHS, AUTH_EVENTS, EXTERNAL_ROUTES) {

        const HelpersService = {
            startSystemTitle: startSystemTitle,
            atualizaTitulo: atualizaTitulo,
            tryParseJSON: tryParseJSON,
            handleChangeStart: handleChangeStart,
            handleStateChangeSuccess: handleStateChangeSuccess,
            handleViewContentLoaded: handleViewContentLoaded,
            handleOnRun: handleOnRun,
            caminhoPastaImagem: caminhoPastaImagem,
            caminhoArquivo: caminhoArquivo,
            isImage: isImage,
            isObjectEmpty: isObjectEmpty,
            setErrorMessageForm: setErrorMessageForm,
            removeErrorMessageForm: removeErrorMessageForm,

            //VARS
            flowOptionsDefaults: getFlowOptionsDefaults,
            flowOptionsDefaultsGaleria: getFlowOptionsDefaultsGaleria,
            flowOptionsDefaultsArquivos: getFlowOptionsDefaultsArquivos
        };

        return HelpersService;

        /**
         * Retorna a variável de configuração padrão do plugin flowJS para upload default
         */
        function getFlowOptionsDefaults() {
            const token = DataRepositorioService.get('userToken');
            let headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            return {
                permanentErrors: [500, 501, 428],
                maxChunkRetries: 1,
                chunkRetryInterval: 5000,
                simultaneousUploads: 1,
                allowDuplicateUploads: true,
                singleFile: true,
                target: EXTERNAL_ROUTES.api + 'images/upload',
                headers: headers
            }
        }

        /**
         * Retorna a variável de configuração padrão do plugin flowJS para upload de galeria
         */
        function getFlowOptionsDefaultsGaleria() {
            const token = DataRepositorioService.get('userToken');
            let headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            return {
                permanentErrors: [500, 501, 428],
                maxChunkRetries: 1,
                chunkRetryInterval: 5000,
                allowDuplicateUploads: true,
                simultaneousUploads: 5,
                target: EXTERNAL_ROUTES.api + 'images/upload',
                headers: headers
            }
        }

        /**
         * Retorna a variável de configuração padrão do plugin flowJS para upload de arquivos
         */
        function getFlowOptionsDefaultsArquivos() {
            const token = DataRepositorioService.get('userToken');
            let headers = {};

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            return {
                permanentErrors: [500, 501, 428],
                maxChunkRetries: 1,
                chunkRetryInterval: 5000,
                simultaneousUploads: 1,
                allowDuplicateUploads: true,
                target: EXTERNAL_ROUTES.api + 'files/upload',
                headers: headers
            }
        }

        /**
         * Inicia a variável de título e descrição do sistema
         */
        function startSystemTitle() {
            $rootScope.app = {
                name: '',
                lastName: ' - CMS 5àsec',
                description: 'CMS 5àsec',
                layout: {
                    menuPin: false,
                    menuBehind: false
                },
                author: '5àsec'
            };
        }

        /**
         * Atualiza o título da pagina atual
         * @param {string} titulo - Título a ser utilizado
         */
        function atualizaTitulo(titulo) {
            $rootScope.app.name = titulo + $rootScope.app.lastName;
            window.document.title = titulo + $rootScope.app.lastName;
        }

        /**
         * Tenta converter uma string json
         * @param {string} src - String a ser convertida
         */
        function tryParseJSON(jsonString) {
            try {
                var o = JSON.parse(jsonString);

                // Handle non-exception-throwing cases:
                // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
                // but... JSON.parse(null) returns null, and typeof null === "object",
                // so we must check for that, too. Thankfully, null is falsey, so this suffices:
                if (o && typeof o === 'object') {
                    return o;
                }
            } catch (e) {
                return false;
            }

            return false;
        }

        /**
         * Método auxiliar para realizar verificações de layout e outros services
         * no momento de ínicio da troca de rota
         * 
         * @param {object} nextRoute - Objeto com as informações da próxima rota a ser carregada
         */
        function handleChangeStart(nextRoute) {
            LoadingPanelService.start($('#page-content'));

            if ($('.modal-backdrop').length) {
                $('.modal-backdrop').remove();
            }

            if ($(window).width() <= 991 && ($('.page-sidebar').hasClass('visible') || $('body').hasClass('sidebar-visible') || $('body').hasClass('sidebar-open'))) {
                $('[data-pages="sidebar"]')
                    .data('pg.sidebar')
                    .forceClose();
            }

            //Remove todas as notificações na troca de pagina
            if ($window.PNotify) {
                $window.PNotify.removeAll();
            }

            if (!AuthService.isAuthenticated() && nextRoute.url.indexOf('login') < 0) {
                event.preventDefault();

                $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
            }
        }

        /**
         * Método auxiliar para realizar verificações de layout e outros services
         * no sucesso de troca de rotas
         */
        function handleStateChangeSuccess() {
            NavigationService.handleChangeSuccess();

            // $window.ga('send', 'pageview', {
            //     page: $location.url()
            // });

            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }

        /**
         * Método auxiliar para realizar verificações de layout e outros services
         * quando o conteúdo da tela é carregado
         */
        function handleViewContentLoaded() {
            LoadingPanelService.stop($('#page-content'));
        }

        /**
         * Método auxiliar para carregar vars, eventos, watchers e realizar verificações
         * no momento do run do projeto
         */
        function handleOnRun() {
            if ($window.location.protocol == 'http:' && $window.location.hostname == 'siteadmin.5asec.com.br') {
                $window.location.href = $window.location.href.replace('http://', 'https://');
            }

            $rootScope.BASE_PATHS = BASE_PATHS;

            $rootScope.$on(AUTH_EVENTS.notAuthenticated, AuthService.doLogout);
        }

        /**
         * Realiza a criação do caminho relativo de acesso as imagens da API
         * @param {string} area - Área a ser acessada
         * @param {string|int} id - Id do registro
         * @param {string} arquivo - Nome do arquivo
         */
        function caminhoPastaImagem(area, id, arquivo) {
            return EXTERNAL_ROUTES.api + 'Assets/Uploads/' + area + '/' + id + '/' + arquivo;
        }

        /**
         * Realiza a criação do caminho relativo de acesso aos arquivos da API
         * @param {string} area - Área a ser acessada
         * @param {string} arquivo - Nome do arquivo
         */
        function caminhoArquivo(area, arquivo) {
            return EXTERNAL_ROUTES.api + 'Assets/Uploads/' + area + '/' + arquivo;
        }

        /**
         * Verifica se o resource é uma imagem válida
         * @param {string} src - Url do resource a ser validado
         */
        function isImage(src) {
            var deferred = $q.defer();
            var image = new Image();

            image.onerror = function() {
                deferred.resolve(false);
            };

            image.onload = function() {
                deferred.resolve(true);
            };

            image.src = src;

            return deferred.promise;
        }

        /**
         * Verifica se o objeto está vazio
         * @param {object} obj - Objeto a ser verificado
         */
        function isObjectEmpty(obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    return false;
                }
            }
            return true;
        }

        /**
         * Adiciona as mensagens de erro no elemento informado
         *
         * @param {string} input - Seletor jQuery do elemento
         */
        function setErrorMessageForm(input) {
            if (!$(input).parent().find('.validationMessage').length) {
                $(input).parent()
                    .append('<label class=\'control-label has-error validationMessage\'>Este campo é Obrigatório</label>');

                $(input)
                    .addClass('has-error')
                    .parents('.form-group')
                    .addClass('has-error');
            }
        }

        /**
         * Remove as mensagens de erro no elemento informado
         *
         * @param {string} input - Seletor jQuery do elemento
         */
        function removeErrorMessageForm(input) {
            $(input)
                .parent()
                .find('.validationMessage')
                .remove();

            $(input)
                .removeClass('has-error')
                .parents('.form-group')
                .removeClass('has-error');
        }
    }
}());