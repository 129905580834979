(function() {
    'use strict';

    /** @ngInject */
    angular
        .module('5asecAdmin')
        .constant('AUTH_EVENTS', {
            // loginFailed: 'auth-login-failed',
            // logoutSuccess: 'auth-logout-success',
            // notAuthorized: 'auth-not-authorized',
            notAuthenticated: 'auth-not-authenticated'
        })
        .constant('BASE_PATHS', {
            files: 'angular/components/',
            templateFiles: 'angular/templates/'
        })
        .constant('EXTERNAL_ROUTES', {
            api: 'http://admin-dev.5asec.com.br/api/',
            admin: 'http://admin-dev.5asec.com.br/',
            site: 'http://www-dev.5asec.com.br/'
        });
})();