(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .directive('preventEnterSubmit', PreventEnterSubmit);

    function PreventEnterSubmit() {

        /** @ngInject */
        function PreventEnterSubmitController($scope) {
            this.handle = function(element) {
                element.bind('keydown', function(event) {
                    if ($(event.target).hasClass('ace_text-input') || $(event.target).parents('text-angular') || $(event.target).parents('allow-enter')) {
                        return
                    }

                    if (13 == event.which) {
                        event.preventDefault(); // Doesn't work at all
                        window.stop(); // Works in all browsers but IE...
                        document.execCommand('Stop'); // Works in IE
                        return false; // Don't even know why it's here. Does nothing.
                    }

                    $scope.$on('$destroy', function() {
                        $(el).unbind('keydown');
                    });
                });
            }
        }

        function link(scope, element, attrs, ctrl) {
            ctrl.handle(element);
        }

        return {
            bindToController: true,
            controller: PreventEnterSubmitController,
            controllerAs: 'vm',
            link: link,
            restrict: 'AE',
            scope: {}
        }
    }
}());