(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('SeoService', SeoService)

    /** @ngInject */
    function SeoService(DataApiService) {

        const SeoService = {
            getAllSeo: getAllSeo,
            getSeo: getSeo,
            saveSeo: saveSeo,
            updateSeo: updateSeo,
            deleteSeo: deleteSeo,
            getAllSeoGeral: getAllSeoGeral,
            getSeoGeral: getSeoGeral,
            saveSeoGeral: saveSeoGeral,
            updateSeoGeral: updateSeoGeral,
            deleteSeoGeral: deleteSeoGeral
        };

        return SeoService;

        function getAllSeo() {
            return DataApiService.get('seo');
        }

        function getSeo(seoId) {
            return DataApiService.get(`seo/${seoId}`);
        }

        function saveSeo(seo) {
            return DataApiService.post('seo', seo);
        }

        function updateSeo(seo) {
            return DataApiService.put('seo', seo);
        }

        function deleteSeo(seoId) {
            return DataApiService.delete(`seo/${seoId}`);
        }

        function getAllSeoGeral() {
            return DataApiService.get('seo/geral');
        }

        function getSeoGeral(seoGeralId) {
            return DataApiService.get(`seo/geral/${seoGeralId}`);
        }

        function saveSeoGeral(seoGeral) {
            return DataApiService.post('seo/geral', seoGeral);
        }

        function updateSeoGeral(seoGeral) {
            return DataApiService.put('seo/geral', seoGeral);
        }

        function deleteSeoGeral(seoGeralId) {
            return DataApiService.delete(`seo/geral/${seoGeralId}`);
        }
    }
}());