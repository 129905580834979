(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('SistemaService', SistemaService)

    /** @ngInject */
    function SistemaService($http, DataApiService) {

        const SistemaService = {
            listaServicos: listaServicos,
            listaStatusServico: listaStatusServico,
            updateDependencies: updateDependencies,
            atualizaStatusServico: atualizaStatusServico
        };

        return SistemaService;

        function listaServicos() {
            return DataApiService.get('sistema/busca-servicos');
        }

        function updateDependencies() {
            return $http.post('http://www-dev.5asec.com.br/sistema/recarrega-dependencies');
        }

        function listaStatusServico(serviceName) {
            return DataApiService.patch('sistema/recupera-status-servico/' + serviceName);
        }

        function atualizaStatusServico(serviceName, actionName) {
            return DataApiService.get('sistema/atualiza-status-servico/' + serviceName + '/' + actionName);
        }
    }
}());