(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('SitemapService', SitemapService)

    /** @ngInject */
    function SitemapService(DataApiService) {

        const SitemapService = {
            criaSitemaps: criaSitemaps
        };

        return SitemapService;

        function criaSitemaps() {
            return DataApiService.get('sistema/cria-sitemaps');
        }
    }
}());