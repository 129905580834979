(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .provider('DeviceType', DeviceTypeProvider)

    /** @ngInject */
    function DeviceTypeProvider() {

        const DeviceTypeProvider = {
            isMobileDevice: false,
            isMobile: false,
            isTablet: false,         
            $get: $get,  
            get: DeviceTypeController
        };

        return DeviceTypeProvider;

        /** @ngInject */
        function DeviceTypeController($window) {
            return {
                checkIsMobile: checkIsMobile
            }

            function checkIsMobile() {
                if ($window.width() <= 768) {
                    this.isMobile = true;
                    this.isTablet = false;
                } else if ($window.width() > 768 && $window.width() <= 991) {
                    this.isMobile = false;
                    this.isTablet = true;
                }

                this.isMobileDevice = this.isMobile || this.isTablet;
            }
        }

        function $get() {
            return {}
        }

    }
}());