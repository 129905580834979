(function() {
    'use strict';

    angular
        .module('5asecAdmin')
        .service('MidiaService', MidiaService)

    /** @ngInject */
    function MidiaService(DataApiService) {

        const MidiaService = {
            getAllMidias: getAllMidias,
            getMidia: getMidia,
            saveMidia: saveMidia,
            updateMidia: updateMidia,
            deleteMidia: deleteMidia
        };

        return MidiaService;

        function getAllMidias() {
            return DataApiService.get('midias');
        }

        function getMidia(midiaId) {
            return DataApiService.get(`midias/${midiaId}`)
        }

        function saveMidia(midia) {
            return DataApiService.post('midias/', midia)
        }

        function updateMidia(midia) {
            return DataApiService.put('midias/', midia);
        }

        function deleteMidia(midiaId) {
            return DataApiService.delete(`midias/${midiaId}`);
        }
    }
}());